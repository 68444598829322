import { ArrowLeftOutlined } from "@ant-design/icons";
import { Alert, Button, Form, Input, Steps, Typography, message } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { UNKNOWN_ERROR_MESSAGE } from "../constants";
import { persistLinkState } from "../hooks/useLinkTracker";
import { captureException } from "../sentry";
import Store from "../stateStore";
import { extractLinkParamsFromState, getBackendUrl, STORES } from "../utils";

const { Text, Link, Title, Paragraph } = Typography;
const DEFAULT_IMAGE_WIDTH = 600;

const { Step } = Steps;

type Props = {
  clientSecret: string;
  onBack: () => any;
  onCancel: () => any;
  onComplete: (public_token: string) => any;
};

function TallyAuth(props: Props) {
  const { onComplete } = props;

  const [loading, setIsLoading] = React.useState(false);
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const stateStore = Store.useStore();
  const organizationName = stateStore.get("orgName");
  const autoPlatform = stateStore.get("autoPlatform");
  const [form] = Form.useForm();
  const [validationResponse, setValidationResponse] = useState([]);
  const [itemId, setItemId] = useState("");
  const [companyId, setCompanyId] = useState("");
  const [companyIdStatus, setCompanyIdStatus] = useState("...");
  const [currentProgressDot, setProgressDot] = useState(0);
  const [fileDownloaded, setFileDownloaded] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();

  const companyIdSentString = "Successfully saved Account ID.";
  const isCompanyIdProvided = () => {
    return companyIdStatus === companyIdSentString;
  };

  // Keep updating the status of ProgressSteps based on what the user has done or is doing
  useEffect(() => {
    if (fileDownloaded) {
      setProgressDot(7);
    } else if (isCompanyIdProvided()) {
      setProgressDot(2);
    } else if (companyId !== "") {
      setProgressDot(1);
    }
  }, [companyId, companyIdStatus, currentProgressDot, fileDownloaded]);

  const errorRef = React.useRef<HTMLInputElement>(null);

  const handleAuthError = (e: any) => {
    captureException(e);
    if (e.response?.data?.error_message) {
      setError(e.response.data?.error_message);
    } else {
      setError(UNKNOWN_ERROR_MESSAGE);
    }
  };

  const handleFinish = (values: any) => {
    const { apiKey, password } = values;
    // try to post to backend
    setIsLoading(true);
    const finishPayload = {
      ...extractLinkParamsFromState(stateStore),
      link_update_id: itemId === "" ? stateStore.get("itemId") : itemId,
    };
    axios
      .post(`${getBackendUrl()}/tally/link-verify`, finishPayload)
      .then((res) => {
        const { data } = res;
        const { is_successful, reason, public_token } = data;
        if (is_successful) {
          onComplete(public_token);
        } else {
          setError(reason);
        }
      })
      .catch((e) => {
        const { checkPointResponse } = e.response.data;
        const setValidationResponseAndScroll = () => {
          setValidationResponse(checkPointResponse);
          errorRef.current?.scrollIntoView({
            behavior: "smooth",
          });
        };
        checkPointResponse
          ? setValidationResponseAndScroll()
          : handleAuthError(e);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleDownloadFile = () => {
    setIsLoading(true);
    persistLinkState(STORES.TALLY, stateStore.getState());
    function download() {
      const link = document.createElement("a");
      link.href =
        "https://rutterpublicimages.s3.us-east-2.amazonaws.com/rutterapi+(2).tcp";
      link.download = "Rutter.tcp";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
    download();
    setFileDownloaded(true);
    setPassword(password);
  };

  /**
   * Returns the provided company id to the backend.
   */
  const handleSendCompanyId = () => {
    setIsLoading(true);
    setCompanyIdStatus("Wait...");
    axios
      .post(`${getBackendUrl()}/tally/save-id`, {
        ...extractLinkParamsFromState(stateStore),
        company_id: companyId.trim(),
      })
      .then(({ data }) => {
        const {
          is_successful: isSuccessful,
          reason: errorMessage,
          item_id,
          inbound_access_token,
        } = data;
        if (isSuccessful) {
          setItemId(item_id);
          setCompanyIdStatus(companyIdSentString);
          setPassword(inbound_access_token);
          messageApi.open({
            type: "success",
            content: companyIdSentString,
          });
        } else {
          const errorString =
            errorMessage === "duplicate"
              ? `This account number is already being used with a different connection. Please contact ${stateStore.get(
                  "orgName",
                )} for help.`
              : errorMessage;
          setError(errorString);
          messageApi.open({
            type: "error",
            content: errorString,
          });
        }
      })
      .catch((e) => {
        setError(e.message);
      })
      .finally(() => setIsLoading(false));
  };

  function renderStep(step: number) {
    if (step === 0) {
      return (
        <div>
          First, make sure you're on your desktop computer or laptop with Tally
          running. You'll need access to your Tally files to complete the setup.
        </div>
      );
    } else if (step === 1) {
      return (
        <div>
          <div>
            <Input
              placeholder="Customer Account #"
              value={companyId}
              onChange={(e) => {
                setCompanyId(e.target.value.trim());
              }}
            />
          </div>
          <div>
            <Button
              type="primary"
              className="mt-2"
              style={{ marginRight: "8px" }}
              disabled={loading || companyId === "" || step > 1}
              onClick={handleSendCompanyId}
            >
              Submit
            </Button>
          </div>
          Please enter the name of your company.
        </div>
      );
    } else if (step === 2) {
      return (
        <div>
          <div>
            <b>
              <Link
                onClick={handleDownloadFile}
                disabled={!isCompanyIdProvided()}
              >
                Click here to download the TCP file.
              </Link>
            </b>
          </div>
        </div>
      );
    } else if (step === 3) {
      return (
        <div>
          <div>
            Open Tally to show the Gateway of Tally page, then go to F1: Help →
            TDLs & AddOns → F4:Manage Local TDLs. In the TDL Configuration
            screen, click Specify Path in the Specify File Path pop-up page and
            then put in the path to the TCP file you downloaded above. If you
            have an issue with this step,{" "}
            <Link
              href="https://www.labhsoftware.in/2020/12/10/setup-tdls-and-add-ons-in-tally-prime/"
              target="_blank"
            >
              visit this article
            </Link>{" "}
            for more instructions.
          </div>
          <img
            width={DEFAULT_IMAGE_WIDTH}
            src="https://razorpay.com/docs/build/browser/assets/images/x-tally-int-tdls-addons.jpg"
            alt=""
          />
        </div>
      );
    } else if (step === 4) {
      return (
        <div>
          <div>
            Go to the Rutter settings and paste the following value into the
            Access Token field.
          </div>
          <div>
            <b>
              <Link
                onClick={() => {
                  navigator.clipboard.writeText(password);
                }}
                disabled={!isCompanyIdProvided()}
              >
                Click here
              </Link>
            </b>{" "}
            to copy your access token: {password}{" "}
          </div>
          <div>Paste the following value into the connection ID field:</div>
          <div>
            <b>
              <Link
                onClick={() => {
                  navigator.clipboard.writeText(itemId);
                }}
                disabled={!isCompanyIdProvided()}
              >
                Click here
              </Link>
            </b>{" "}
            to copy your connection ID: {itemId}{" "}
          </div>
        </div>
      );
    } else if (step === 5) {
      return <div>Click "Send Masters & Reports"</div>;
    } else if (step === 6) {
      return (
        <div>
          <div>Almost done! Click below to verify your connection ⚡️</div>
          <div>
            <Button
              disabled={loading || !password || !isCompanyIdProvided()}
              className="mt-2"
              type="primary"
              onClick={handleFinish}
            >
              Verify Connection
            </Button>
          </div>
        </div>
      );
    } else {
      return null;
    }
  }

  return (
    <div
      style={{
        padding: "1.6rem 1rem 200px",
        minHeight: "100%",
        overflowY: "scroll",
      }}
    >
      {contextHolder}
      <div style={{ display: "flex" }} className="">
        {!autoPlatform && (
          <Button
            onClick={props.onBack}
            icon={<ArrowLeftOutlined />}
            className="flex items-center"
          >
            Back
          </Button>
        )}
      </div>
      <div
        className="flex  flex-column"
        style={{ justifyContent: "center", paddingBottom: "20px" }}
      >
        <img
          style={{ height: "80px" }}
          src="https://rutterpublicimages.s3.us-east-2.amazonaws.com/Tally_-_Logo.png"
          alt=""
        />
      </div>
      <Steps direction="vertical" progressDot current={currentProgressDot}>
        <Step title="Open Tally" description={renderStep(0)} />
        <Step title="Enter your company name" description={renderStep(1)} />
        <Step title="Download the Tally TCP file" description={renderStep(2)} />
        <Step
          title="Open Tally and install the TCP file"
          description={renderStep(3)}
        />
        <Step title="Configure Integration" description={renderStep(4)} />
        <Step title="Run the connection" description={renderStep(5)} />
        <Step title="Verify the connection" description={renderStep(6)} />
      </Steps>
      {error && (
        <Alert
          className="mb-4"
          type="error"
          message={"Error"}
          description={error}
          showIcon
        ></Alert>
      )}
    </div>
  );
}

export default TallyAuth;
