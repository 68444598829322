import { ArrowLeftOutlined } from "@ant-design/icons";
import { Alert, Button, Input, Typography } from "antd";
import axios from "axios";
import qs from "qs";
import { useCallback, useState } from "react";
import { UNKNOWN_ERROR_MESSAGE } from "../constants";
import { persistLinkState } from "../hooks/useLinkTracker";
import { captureException } from "../sentry";
import Store from "../stateStore";
import { getBackendUrl, STORES } from "../utils";
import PlatformPageText from "./shared/PlatformPageText";
import TextUnderConnect, {
  shouldShowTextUnderConnect,
} from "./shared/TextUnderConnect";

const { Link } = Typography;

type Props = {
  clientSecret: string;
  onBack: () => any;
  onCancel: () => any;
  onComplete: (public_token: string) => any;
};

const Shopware = (props: Props) => {
  const { clientSecret, onComplete } = props;

  const [loading, setIsLoading] = useState(false);
  const [isRedirecting] = useState(false);
  const [error, setError] = useState("");
  const store = Store.useStore();
  const autoPlatform = store.get("autoPlatform");
  const isSandbox = store.get("isSandbox");
  const organization = store.get("organization");
  const orgId = store.get("orgId");

  const itemId = store.get("itemId");
  const [website, setWebsite] = useState("");
  const [username, setUsername] = useState(isSandbox ? "user_good" : "");
  const [password, setPassword] = useState(isSandbox ? "pass_good" : "");
  const [showClientId, setShowClientId] = useState(false);
  const [clientId, setClientId] = useState("");

  const handleAuthError = useCallback((e: any) => {
    captureException(e);
    if (e.response?.data?.error_message) {
      setError(e.response.data?.error_message);
    } else {
      setError(UNKNOWN_ERROR_MESSAGE);
    }
  }, []);

  const handleFakeComplete = useCallback(() => {
    setIsLoading(true);
    axios
      .post(`${getBackendUrl()}/link/token/fulfill`, {
        type: STORES.SHOPWARE,
        clientId: clientSecret,
        website: "shop_good",
        username: "user_good",
        password: "pass_good",
      })
      .then((response) => {
        const { data } = response;
        const { public_token } = data;
        onComplete(public_token);
      })
      .catch(handleAuthError)
      .finally(() => {
        setIsLoading(false);
      });
  }, [clientSecret, handleAuthError, onComplete]);

  const handleComplete = async () => {
    // validate domain name formatting
    if (!website.includes(".")) {
      setError("Please include the store domain name in full.");
      return;
    }
    if (website.includes("/")) {
      setError(
        "Please remove any '/' or subdirectories from the store domain name.",
      );
      return;
    }
    if (website.startsWith("http")) {
      setError(
        "Please only input the store domain name and remove the http:// or https:// at the front",
      );
      return;
    }

    const storeUrl = `${website}`;

    setIsLoading(true);

    const query = qs.stringify({
      linkupdate: itemId ? itemId : undefined,
    });

    persistLinkState(STORES.SHOPWARE, store.getState());

    // logic to pass to routes.ts appUrlHandler in rutter-backend
    axios
      .post(`${getBackendUrl()}/shopware/verify-credential?${query}`, {
        type: STORES.SHOPWARE,
        fullDomain: `https://${storeUrl}`,
        username: username,
        password: password,
        organizationId: orgId,
        clientId,
      })
      .then((response) => {
        const { data } = response;
        const { public_token } = data;
        onComplete(public_token);
      })
      .catch(handleAuthError)
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <div style={{ padding: "1.6rem" }}>
      <div style={{ display: "flex" }} className="">
        {!autoPlatform && (
          <Button
            onClick={props.onBack}
            icon={<ArrowLeftOutlined />}
            className="flex items-center"
          >
            Back
          </Button>
        )}
      </div>
      <div
        className="flex items-center flex-column mt-4"
        style={{ justifyContent: "center", paddingBottom: "20px" }}
      >
        <img
          style={{ height: "80px" }}
          src="https://www.shopware.com/media/unknown/f7/73/18/shopware_logo_vertical_blue.svg"
          alt=""
        />
      </div>

      {organization?.platformPageText && (
        <div className="mb-2 text-xs">
          <PlatformPageText></PlatformPageText>
        </div>
      )}

      <div className="font-semibold">Store URL</div>
      <div className="text-sm">
        Please enter in your Shopware domain. If your domain is
        example.shopware.store, then enter in "example.shopware.store".
      </div>
      <div style={{ marginBottom: 16 }} className="mt-2">
        <Input
          addonBefore="http://"
          placeholder="Store Domain"
          value={website}
          onChange={(e) => setWebsite(e.target.value)}
        />
      </div>
      <br />
      <div className="font-semibold">User Credentials</div>
      <div className="text-sm">
        Please enter your Shopware username, password, and client ID.
      </div>
      <div className="font-semibold">Admin Username</div>
      <div style={{ marginBottom: 16 }} className="mt-2">
        <Input
          placeholder=""
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />
      </div>
      <div className="font-semibold">Admin Password</div>
      <div style={{ marginBottom: 16 }} className="mt-2">
        <Input
          placeholder=""
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          type="password"
        />
      </div>
      {showClientId ? (
        <>
          <div className="font-semibold">Client ID</div>
          <div style={{ marginBottom: 16, overflowWrap: "break-word" }}>
            Please provide a client ID from the Shopware integration settings.
            You can find instructions on how to make an integration
            <b>
              <a
                // TODO: replace with link to an actual guide that isn't internal
                href="https://www.notion.so/rutterapi/Shopware-Process-8b6c482173624693a28a9537205887e3"
                target="_blank"
                rel="noopener noreferrer"
              >
                {" "}
                here.
              </a>
            </b>
          </div>
          <div className="font-semibold">Client ID</div>
          <div style={{ marginBottom: 16 }} className="mt-2">
            <Input
              placeholder=""
              value={clientId}
              onChange={(e) => setClientId(e.target.value)}
            />
          </div>
        </>
      ) : (
        <>
          <Link
            onClick={() => setShowClientId(true)}
            className="flex items-center center text-center"
            style={{ marginBottom: 16 }}
          >
            Add your client ID
          </Link>
        </>
      )}
      {isSandbox ? (
        <Button
          type="primary"
          className=""
          block
          disabled={loading}
          onClick={handleFakeComplete}
        >
          {loading ? "Connecting..." : "Sandbox: Connect Test Shopify"}
        </Button>
      ) : (
        <Button
          type="primary"
          block
          disabled={
            loading ||
            website === "" ||
            username === "" ||
            password === "" ||
            clientId === ""
          }
          onClick={handleComplete}
        >
          {loading
            ? isRedirecting
              ? "Redirecting to Shopware, please wait..."
              : "Connecting..."
            : "Connect Shopware"}
        </Button>
      )}

      {shouldShowTextUnderConnect(organization) && (
        <div className="mt-2 text-xs">
          <TextUnderConnect></TextUnderConnect>
        </div>
      )}

      {error && (
        <Alert
          className="mt-4"
          type="error"
          message={"Error"}
          description={error}
          showIcon
        ></Alert>
      )}
    </div>
  );
};

export default Shopware;
