import { ArrowLeftOutlined } from "@ant-design/icons";
import { Alert, Button, Spin } from "antd";
import axios from "axios";
import qs from "qs";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { UNKNOWN_ERROR_MESSAGE } from "../constants";
import { persistLinkState } from "../hooks/useLinkTracker";
import { captureException } from "../sentry";
import Store from "../stateStore";
import { STORES, extractLinkParamsFromState, getBackendUrl } from "../utils";
import PlatformPageText from "./shared/PlatformPageText";

type EbayProps = {
  clientSecret: string;
  onBack: () => void;
  onCancel: () => void;
  onComplete: (publicToken: string) => void;
};

function Ebay({ clientSecret, onBack, onCancel, onComplete }: EbayProps) {
  const store = Store.useStore();
  const history = useHistory();
  const autoPlatform = store.get("autoPlatform");
  const organization = store.get("organization");
  const isSandbox = store.get("isSandbox");
  const origin = store.get("origin");
  const nonce = store.get("nonce");

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>("");

  const handleAuthError = (e: any) => {
    captureException(e);
    if (e.response?.data?.error_message) {
      setError(e.response.data?.error_message);
    } else {
      setError(UNKNOWN_ERROR_MESSAGE);
    }
  };

  React.useEffect(() => {
    if (isSandbox) {
      store.set("handleSandboxComplete")(handleFakeComplete);
      history.push("/sandboxauth");
    } else {
      handleComplete();
    }
  }, []);

  const handleFakeComplete = async () => {
    setLoading(true);
    try {
      const response = await axios.post(
        `${getBackendUrl()}/link/token/fulfill`,
        {
          type: STORES.EBAY,
          clientId: clientSecret,
          username: "user_good",
          password: "pass_good",
        },
      );
      const { data } = response;
      const { public_token } = data;
      onComplete(public_token);
    } catch (e: any) {
      handleAuthError(e);
    }
    setLoading(false);
  };

  function handleComplete() {
    setLoading(true);
    try {
      persistLinkState(STORES.EBAY, store.getState());
      const q = qs.stringify(extractLinkParamsFromState(store));
      const url = `${getBackendUrl()}/ebay/link?${q}`;
      window.location.replace(url);
    } catch (e) {
      handleAuthError(e);
      setLoading(false);
    }
  }

  return (
    <div
      style={{
        position: "absolute",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: 99,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Spin></Spin>
    </div>
  );

  // Removed the click button UI for bloom
  return (
    <div style={{ padding: "1.6rem" }}>
      <div style={{ display: "flex" }}>
        {!autoPlatform && (
          <Button
            onClick={onBack}
            icon={<ArrowLeftOutlined />}
            className="flex items-center"
          >
            Back
          </Button>
        )}
      </div>
      <div
        className="flex items-center flex-column mt-4"
        style={{ justifyContent: "center", paddingBottom: 30 }}
      >
        <img
          style={{ height: "80px" }}
          src="https://rutterpublicimages.s3.us-east-2.amazonaws.com/ebaylogosquare.png"
          alt=""
        />
      </div>
      {organization?.platformPageText && (
        <div className="mb-2 text-xs">
          <PlatformPageText />
        </div>
      )}
      <Button
        type="primary"
        block
        disabled={loading}
        onClick={isSandbox ? handleFakeComplete : handleComplete}
      >
        {isSandbox ? "Sandbox: Connect Test Ebay" : "Connect Ebay"}
      </Button>
      {error && (
        <Alert
          className="mt-4"
          type="error"
          message={"Error"}
          description={error}
          showIcon
        />
      )}
    </div>
  );
}

export default Ebay;
