import { ArrowLeftOutlined } from "@ant-design/icons";
import styled from "@emotion/styled";
import { Alert, Button, Input, Typography } from "antd";
import axios from "axios";
import qs from "qs";
import { useState } from "react";
import * as Yup from "yup";
import { CountryPicker } from "../components/ScrapingBeeCountrySelect";
import { UNKNOWN_ERROR_MESSAGE } from "../constants";
import { persistLinkState } from "../hooks/useLinkTracker";
import { captureException } from "../sentry";
import Store from "../stateStore";
import { extractLinkParamsFromState, getBackendUrl, STORES } from "../utils";

const { Text, Link, Title, Paragraph } = Typography;

type Props = {
  clientSecret: string;
  onBack: () => any;
  onCancel: () => any;
  onComplete: any;
};

const PRESTASHOP_REQUIRED_PERMISSIONS = [
  "shops",
  "products",
  "orders",
  "order_payments",
  "customers",
  "tags",
  "stock_availables",
  "combinations",
  "product_options",
  "product_option_values",
  "carriers",
  "order_states",
  "order_carriers",
  "order_details",
  "order_slip",
  "addresses",
  "states",
  "countries",
  "configurations",
  "currencies",
  "languages",
];

const READ_WRITE_CONFIG = "READWRITE";
const READ_ONLY_CONFIG = "READ";

function extractScopesInfo(scopes: string): {
  readWrite: string;
  scopes: string;
} {
  let configReadOrWrite = "read and write";
  if (scopes.startsWith(READ_ONLY_CONFIG)) {
    if (scopes.startsWith(READ_WRITE_CONFIG)) {
      scopes = scopes.substring(READ_WRITE_CONFIG.length);
    } else {
      configReadOrWrite = "read";
      scopes = scopes.substring(READ_ONLY_CONFIG.length);
    }
  }
  const prestashopScopesText =
    scopes !== ""
      ? scopes.split(",").join(", ")
      : PRESTASHOP_REQUIRED_PERMISSIONS.join(", ");
  return { readWrite: configReadOrWrite, scopes: prestashopScopesText };
}

function PrestaShop(props: Props) {
  const { onComplete, clientSecret } = props;

  const stateStore = Store.useStore();
  const isSandbox = stateStore.get("isSandbox");
  const organizationId = stateStore.get("orgId");
  const org = stateStore.get("organization");
  const { prestashopScopes = "" } = org || {};
  const { readWrite, scopes } = extractScopesInfo(prestashopScopes ?? "");
  const itemId = stateStore.get("itemId");
  const autoPlatform = stateStore.get("autoPlatform");

  const [url, setUrl] = useState(isSandbox ? "shop_good" : "");
  const [authKey, setAuthKey] = useState(isSandbox ? "key_good" : "");
  const [countryCode, setCountryCode] = useState("us");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const handleAuthError = (e: any) => {
    captureException(e);
    if (e.response?.data?.error_message) {
      setError(e.response.data?.error_message);
    } else {
      setError(UNKNOWN_ERROR_MESSAGE);
    }
  };

  const handleFakeComplete = () => {
    setLoading(true);
    axios
      .post(`${getBackendUrl()}/link/token/fulfill`, {
        type: STORES.PRESTASHOP,
        clientId: clientSecret,
        authKey: authKey,
        url: url,
        countryCode,
      })
      .then((response) => {
        const { data } = response;
        const { public_token } = data;
        onComplete(public_token);
      })
      .catch(handleAuthError)
      .finally(() => {
        setLoading(false);
      });
  };

  const handleComplete = () => {
    setLoading(true);

    if (!url) {
      setError("Please enter a valid PrestaShop domain.");
      return;
    }

    if (!authKey) {
      setError("Please enter a valid PrestaShop Auth Key.");
      return;
    }

    let query = qs.stringify({
      linkupdate: itemId ? itemId : undefined,
      ...extractLinkParamsFromState(stateStore),
    });

    if (query !== "") {
      query = "?" + query;
    }

    persistLinkState(STORES.PRESTASHOP, stateStore.getState());

    axios
      .post(`${getBackendUrl()}/prestashop/verify-credential${query}`, {
        authKey: authKey,
        url: url,
        countryCode,
        organizationId: organizationId,
        ...extractLinkParamsFromState(stateStore),
      })
      .then((response) => {
        const { data } = response;
        const { public_token } = data;
        onComplete(public_token);
      })
      .catch(handleAuthError)
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div style={{ padding: "1.6rem" }}>
      <div style={{ display: "flex" }} className="">
        {!autoPlatform && (
          <Button
            onClick={props.onBack}
            icon={<ArrowLeftOutlined />}
            className="flex items-center"
          >
            Back
          </Button>
        )}
      </div>
      <div
        className="flex items-center flex-column mt-4"
        style={{ justifyContent: "center", paddingBottom: "20px" }}
      >
        <img
          style={{ height: "80px" }}
          src="https://rutterpublicimages.s3.us-east-2.amazonaws.com/prestashop.png"
          alt=""
        />
      </div>
      <div className="font-semibold">Full Domain</div>
      <div>
        Please provide the full URL domain to your website's API (e.g.
        https://myshop.com/api). Make sure to include the /api ending.
      </div>
      <div style={{ marginBottom: 16 }} className="mt-2">
        <Input
          placeholder="https://example.com/api"
          value={url}
          onChange={(e) => setUrl(e.target.value)}
        />
      </div>
      <div className="font-semibold">Auth Key / Access Key</div>
      <div style={{ overflowWrap: "break-word" }}>
        Please provide an Auth Key from the PrestaShop admin portal.{" "}
        <Link
          href="https://devdocs.prestashop.com/1.7/webservice/tutorials/creating-access/#enable-the-webservice"
          target="_blank"
          rel="noopener noreferrer"
        >
          Click here to find instructions on how to make an Auth key.{" "}
        </Link>
        We need at least the permissions to <b>{readWrite}</b>: {scopes}.
      </div>
      <div style={{ marginBottom: 16 }} className="mt-2">
        <Input
          placeholder=""
          value={authKey}
          onChange={(e) => setAuthKey(e.target.value)}
        />
      </div>
      <>
        <div className="font-semibold">Country</div>
        <div>Please select your country.</div>
        <div style={{ marginBottom: 16 }} className="mt-2">
          <CountryPicker
            handleChange={(e) => {
              setCountryCode(e);
            }}
          />
        </div>
      </>
      {isSandbox ? (
        <Button
          type="primary"
          className=""
          block
          disabled={loading}
          onClick={handleFakeComplete}
        >
          {loading ? "Connecting..." : "Sandbox: Connect Test PrestaShop"}
        </Button>
      ) : (
        <Button
          type="primary"
          block
          disabled={
            loading ||
            !Yup.string().url().isValidSync(url) ||
            authKey === "" ||
            url === ""
          }
          onClick={handleComplete}
        >
          {loading ? "Connecting..." : "Connect PrestaShop"}
        </Button>
      )}
      {error && (
        <Alert
          className="mt-4"
          type="error"
          message={"Error"}
          description={error}
          showIcon
        ></Alert>
      )}
    </div>
  );
}

export default PrestaShop;

const Container = styled.div``;
