import { ArrowLeftOutlined } from "@ant-design/icons";
import { Alert, Button, Form, Input, Steps, Typography } from "antd";
import axios from "axios";
import React, { useState } from "react";
import { UNKNOWN_ERROR_MESSAGE } from "../constants";
import { captureException } from "../sentry";
import Store from "../stateStore";
import { extractLinkParamsFromState, getBackendUrl } from "../utils";

const { Text, Link, Title, Paragraph } = Typography;

const { Step } = Steps;

type Props = {
  clientSecret: string;
  onBack: () => any;
  onCancel: () => any;
  onComplete: (public_token: string) => any;
};

function BigCommerceApiAccount(props: Props) {
  const { onComplete } = props;

  const [loading, setIsLoading] = React.useState(false);
  const [website, setWebsite] = useState("");
  const [error, setError] = useState("");
  const stateStore = Store.useStore();
  const organizationName = stateStore.get("orgName");
  const storeUrl = stateStore.get("bigCommerceStoreUrl");
  const nonce = stateStore.get("nonce");
  const origin = stateStore.get("origin");
  const autoPlatform = stateStore.get("autoPlatform");
  const publicKey = stateStore.get("publicKey");
  const org = stateStore.get("organization");
  const [showPrivate, setShowPrivate] = React.useState(false);
  const [form] = Form.useForm();
  const [windowOpened, setWindowOpened] = React.useState(false);

  const handleAuthError = (e: any) => {
    captureException(e);
    if (e.response?.data?.error_message) {
      setError(e.response.data?.error_message);
    } else {
      setError(UNKNOWN_ERROR_MESSAGE);
    }
  };

  function myPopup(
    myURL: string,
    title: string,
    myWidth: number,
    myHeight: number,
  ) {
    var left = (window.screen as any).width / 2;
    left = window.innerWidth;
    var top = 0;
    var myWindow = window.open(
      myURL,
      title,
      "toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=" +
        myWidth +
        ", height=" +
        myHeight +
        ", top=" +
        top +
        ", left=" +
        left,
    );
    if (myWindow) {
      setWindowOpened(true);
      var pollTimer = window.setInterval(function () {
        if (myWindow?.closed !== false) {
          // !== is required for compatibility with Opera
          window.clearInterval(pollTimer);
          setWindowOpened(false);
        }
      }, 200);
    }
  }

  const handleFinish = (values: any) => {
    const { clientId, clientSecret, accessToken } = values;
    // try to post to backend
    setIsLoading(true);
    axios
      .post(`${getBackendUrl()}/link/bigcommerceapiaccount`, {
        clientId,
        clientSecret,
        accessToken,
        storeName: storeUrl,
        publicKey,
        ...extractLinkParamsFromState(stateStore),
      })
      .then((res) => {
        const { data } = res;
        const { public_token } = data;
        onComplete(public_token);
      })
      .catch((e) => {
        handleAuthError(e);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const renderDescriptionForPermissions = () => {
    const permissionsMapping: any = {};
    // https://developer.bigcommerce.com/api-docs/getting-started/authentication/rest-api-authentication
    const scopes =
      org?.bigCommerceScopes ||
      "store_v2_customers store_v2_information_read_only store_v2_orders store_v2_products store_v2_transactions";
    const splitScopes = scopes.split(" ");
    const READ_ACCESS = "read-only";
    const READ_WRITE_ACCESS = "modify";
    let unMapped: string[] = [];
    splitScopes.forEach((scope) => {
      switch (scope) {
        case "store_v2_customers":
          permissionsMapping["Customers"] = READ_WRITE_ACCESS;
          break;
        case "store_v2_customers_read_only":
          permissionsMapping["Customers"] = READ_ACCESS;
          break;
        case "store_v2_information_read_only":
          permissionsMapping["Information & Settings"] = READ_ACCESS;
          break;
        case "store_v2_orders":
          permissionsMapping["Orders"] = READ_WRITE_ACCESS;
          break;
        case "store_v2_orders_read_only":
          permissionsMapping["Orders"] = READ_ACCESS;
          break;
        case "store_v2_products":
          permissionsMapping["Products"] = READ_WRITE_ACCESS;
          break;
        case "store_v2_products_read_only":
          permissionsMapping["Products"] = READ_ACCESS;
          break;
        case "store_v2_transactions":
          permissionsMapping["Order Transactions"] = READ_WRITE_ACCESS;
          break;
        case "store_v2_transactions_read_only":
          permissionsMapping["Order Transactions"] = READ_ACCESS;
          break;
        case "store_v2_content_read_only":
          permissionsMapping["Content"] = READ_ACCESS;
          break;
        case "store_content_checkout_read_only":
          permissionsMapping["Checkout Content"] = READ_ACCESS;
          break;
        case "store_v2_marketing_read_only":
          permissionsMapping["Marketing"] = READ_ACCESS;
          break;
        case "store_payments_methods_read":
          permissionsMapping["Get Payment Methods"] = READ_ACCESS;
          break;
        case "store_themes_read_only":
          permissionsMapping["Themes"] = READ_ACCESS;
          break;
        case "store_cart_read_only":
          permissionsMapping["Carts"] = READ_ACCESS;
          break;
        case "store_checkout_read_only":
          permissionsMapping["Checkouts"] = READ_ACCESS;
          break;
        case "store_sites_read_only":
          permissionsMapping["Sites & Routes"] = READ_ACCESS;
          break;
        case "store_channel_settings_read_only":
          permissionsMapping["Channel Settings"] = READ_ACCESS;
          break;
        case "store_channel_listings_read_only":
          permissionsMapping["Channel Listings"] = READ_ACCESS;
          break;
        default:
          unMapped.push(scope);
          break;
      }
    });

    return (
      <div>
        {Object.keys(permissionsMapping).map((key) => {
          return (
            <div key={key} className="flex items-align">
              <div>{key}</div>
              <Text code className="ml-2 items-center flex">
                {permissionsMapping[key]}
              </Text>
            </div>
          );
        })}
        {unMapped.length > 0 && (
          <div className="flex items-align">
            <div>Additional:</div>
            {unMapped.map((scope) => {
              return (
                <Text code className="ml-1 items-center flex">
                  {scope}
                </Text>
              );
            })}
          </div>
        )}
      </div>
    );
  };

  const showEverything = windowOpened || form.isFieldsTouched();

  return (
    <div style={{ padding: "1.6rem 3rem 200px", minHeight: "100%" }}>
      <div style={{ display: "flex" }} className="">
        {!autoPlatform && (
          <Button
            onClick={props.onBack}
            icon={<ArrowLeftOutlined />}
            className="flex items-center"
          >
            Back
          </Button>
        )}
      </div>
      <div
        className="flex  flex-column"
        style={{ justifyContent: "center", paddingBottom: "20px" }}
      >
        <img
          style={{ height: "80px" }}
          src="https://rutterpublicimages.s3.us-east-2.amazonaws.com/bigcommercelogo.webp"
          alt=""
        />
      </div>
      <Alert
        message={
          <div>
            Follow the steps below to share your data with {organizationName}.
          </div>
        }
        type="info"
      />

      <Steps
        current={windowOpened ? 1 : 0}
        direction="vertical"
        className="mt-4"
      >
        <Step
          title={
            <Link
              onClick={() => {
                // depending on size of available screen, we may want to resize link widnow to make it smaller
                let width = window.screen.width - window.innerWidth;
                if (window.innerWidth === window.screen.width) {
                  width = window.innerWidth / 2;
                }
                myPopup(
                  `http://login.bigcommerce.com/deep-links/settings/api-accounts`,
                  "web",
                  width,
                  window.innerHeight,
                );
              }}
            >
              Click here to go to your Store API Accounts page.
            </Link>
          }
        />
        {showEverything && (
          <>
            <Step
              title={`In the new window, click Create API Account with the Create V2/V3 API Token option.`}
              status="process"
            />
            <Step
              title={`Name the API Account "${org?.name}".`}
              status="process"
            />
            <Step
              title={`Set the OAuth Scopes.`}
              status="process"
              description={renderDescriptionForPermissions()}
            />
            <Step title={`Save the API Account.`} status="process" />
            <Step
              title={`Copy the Client ID, Client Secret, and Access Token into the fields below:`}
              status="process"
            />
            <Form
              layout="vertical"
              form={form}
              style={{ paddingBottom: 40 }}
              onFinish={handleFinish}
            >
              <Form.Item
                label="Client ID"
                name="clientId"
                rules={[{ required: true, message: "Client ID required" }]}
              >
                <Input placeholder="fi43hdzokms2ioann85i9d57pzissss" />
              </Form.Item>
              <Form.Item
                label="Client Secret"
                name="clientSecret"
                rules={[{ required: true, message: "Client Secret required" }]}
              >
                <Input placeholder="a784c34691a6cbf420305c464160dade169eab99ce86261cc11d1ae4eff319dd" />
              </Form.Item>
              <Form.Item
                label="Access Token"
                name="accessToken"
                rules={[{ required: true, message: "Access Token required" }]}
              >
                <Input placeholder="jbfft5wjxn18bct6tbkgxwaggj5vfss" />
              </Form.Item>
              <Form.Item>
                {error && (
                  <Alert
                    className="mb-4"
                    type="error"
                    message={"Error"}
                    description={error}
                    showIcon
                  ></Alert>
                )}
                <Button type="primary" htmlType="submit" disabled={loading}>
                  {loading ? "Connecting..." : "Finish"}
                </Button>
              </Form.Item>
            </Form>
          </>
        )}
      </Steps>
    </div>
  );
}

export default BigCommerceApiAccount;
