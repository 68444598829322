import { ArrowLeftOutlined } from "@ant-design/icons";
import styled from "@emotion/styled";
import { Alert, Button, Input } from "antd";
import axios from "axios";
import qs from "qs";
import { useState } from "react";
import { UNKNOWN_ERROR_MESSAGE } from "../constants";
import { captureException } from "../sentry";
import Store from "../stateStore";
import {
  extractLinkParamsFromState,
  getBackButtonCallback,
  getBackendUrl,
  showBackButton,
  STORES,
} from "../utils";

type Props = {
  clientSecret: string;
  onBack: () => any;
  onCancel: () => any;
  onComplete: any;
};

function PrestaShop(props: Props) {
  const { onComplete, clientSecret } = props;

  const stateStore = Store.useStore();
  const isSandbox = stateStore.get("isSandbox");
  const organizationId = stateStore.get("orgId");
  const org = stateStore.get("organization");
  const itemId = stateStore.get("itemId");
  const autoPlatform = stateStore.get("autoPlatform");
  const returnUrl = stateStore.get("customReturnUrl");

  const [url, setUrl] = useState(isSandbox ? "shop_good" : "");
  const [authKey, setAuthKey] = useState(isSandbox ? "key_good" : "");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const handleAuthError = (e: any) => {
    captureException(e);
    if (e.response?.data?.error_message) {
      setError(e.response.data?.error_message);
    } else {
      setError(UNKNOWN_ERROR_MESSAGE);
    }
  };

  const handleFakeComplete = () => {
    setLoading(true);
    axios
      .post(`${getBackendUrl()}/link/token/fulfill`, {
        type: STORES.PRESTASHOP,
        clientId: clientSecret,
        authKey: authKey,
        url: url,
      })
      .then((response) => {
        const { data } = response;
        const { public_token } = data;
        onComplete(public_token);
      })
      .catch(handleAuthError)
      .finally(() => {
        setLoading(false);
      });
  };

  const handleComplete = () => {
    setLoading(true);

    if (!url) {
      setError("Please enter a valid PrestaShop domain.");
      return;
    }

    if (!authKey) {
      setError("Please enter a valid PrestaShop Auth Key.");
      return;
    }

    let query = qs.stringify({
      linkupdate: itemId ? itemId : undefined,
      ...extractLinkParamsFromState(stateStore),
    });

    if (query !== "") {
      query = "?" + query;
    }

    axios
      .post(`${getBackendUrl()}/prestashop/verify-credential${query}`, {
        authKey: authKey,
        url: url,
        organizationId: organizationId,
        ...extractLinkParamsFromState(stateStore),
      })
      .then((response) => {
        const { data } = response;
        const { public_token } = data;
        onComplete(public_token);
      })
      .catch(handleAuthError)
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div style={{ padding: "1.6rem" }}>
      <div style={{ display: "flex" }} className="">
        {showBackButton(autoPlatform, returnUrl) && (
          <Button
            onClick={() =>
              getBackButtonCallback(autoPlatform, props.onBack, returnUrl)
            }
            icon={<ArrowLeftOutlined />}
            className="flex items-center"
          >
            Back
          </Button>
        )}
      </div>
      <div
        className="flex items-center flex-column mt-4"
        style={{ justifyContent: "center", paddingBottom: "20px" }}
      >
        <img
          style={{ height: "80px" }}
          src="https://upload.wikimedia.org/wikipedia/commons/thumb/2/2e/Fnac_Logo.svg/1984px-Fnac_Logo.svg.png"
          alt=""
        />
      </div>
      <div className="font-semibold">Shop ID</div>
      <div>
        Please provide your FNAC Shop ID. Make sure that you have activated the
        API for this account.
      </div>
      <div style={{ marginBottom: 16 }} className="mt-2">
        <Input
          placeholder="ABCDEFG7-A1B1-A2B2-A3B3-123456789123"
          value={url}
          onChange={(e) => setUrl(e.target.value)}
        />
      </div>

      {isSandbox ? (
        <Button
          type="primary"
          className=""
          block
          disabled={loading}
          onClick={handleFakeComplete}
        >
          {loading ? "Connecting..." : "Sandbox: Connect Test PrestaShop"}
        </Button>
      ) : (
        <Button
          type="primary"
          block
          disabled={loading || authKey === ""}
          onClick={handleComplete}
        >
          {loading ? "Connecting..." : "Connect FNAC"}
        </Button>
      )}
      {error && (
        <Alert
          className="mt-4"
          type="error"
          message={"Error"}
          description={error}
          showIcon
        ></Alert>
      )}
    </div>
  );
}

export default PrestaShop;

const Container = styled.div``;
