import { ArrowLeftOutlined } from "@ant-design/icons";
import { Alert, Button, Form, Input, Select, Steps, Typography } from "antd";
import axios from "axios";
import React, { useState } from "react";
import ConnectionErrors from "../components/ConnectionErrors";
import { persistLinkState } from "../hooks/useLinkTracker";
import Store from "../stateStore";
import { extractLinkParamsFromState, getBackendUrl, STORES } from "../utils";

const { Text, Link, Title, Paragraph } = Typography;

const { Step } = Steps;

const DEFAULT_IMAGE_WIDTH = 1000;

type Props = {
  clientSecret: string;
  onBack: () => any;
  onCancel: () => any;
  onComplete: (public_token: string) => any;
};

export enum WALMART_REGION {
  UNITED_STATES = "UNITED_STATES",
  CANADA = "CANADA",
  MEXICO = "MEXICO",
}
const WALMART_REGION_HUMAN_READABLE = {
  UNITED_STATES: "United States",
  CANADA: "Canada",
  MEXICO: "Mexico",
};

function Walmart(props: Props) {
  const { onComplete } = props;
  const [loading, setIsLoading] = React.useState(false);
  const [error, setError] = useState("");
  const store = Store.useStore();
  const autoPlatform = store.get("autoPlatform");
  const publicKey = store.get("publicKey");
  const [form] = Form.useForm();
  const [windowOpened, setWindowOpened] = React.useState(false);
  const [validationResponse, setValidationResponse] = useState([]);
  const [region, setRegion] = useState<string>(WALMART_REGION.UNITED_STATES);
  const errorRef = React.useRef<HTMLInputElement>(null);

  function myPopup(
    myURL: string,
    title: string,
    myWidth: number,
    myHeight: number,
  ) {
    let left = (window.screen as any).width / 2;
    left = window.innerWidth;
    const top = 0;
    const myWindow = window.open(
      myURL,
      title,
      "toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=" +
        myWidth +
        ", height=" +
        myHeight +
        ", top=" +
        top +
        ", left=" +
        left,
    );
    if (myWindow) {
      setWindowOpened(true);
      const pollTimer = window.setInterval(function () {
        if (myWindow?.closed !== false) {
          // !== is required for compatibility with Opera
          window.clearInterval(pollTimer);
          setWindowOpened(false);
        }
      }, 200);
    }
  }

  const handleFinish = (values: any) => {
    const { client_id, client_secet } = values;
    // try to post to backend
    setIsLoading(true);
    persistLinkState(STORES.WALMART, store.getState());
    axios
      .post(`${getBackendUrl()}/walmart/credential-handler`, {
        clientId: client_id,
        clientSecret: client_secet,
        publicKey: publicKey,
        region,
        ...extractLinkParamsFromState(store),
      })
      .then((res) => {
        const { data } = res;
        const { public_token } = data;
        onComplete(public_token);
      })
      .catch((res) => {
        setValidationResponse(res.response.data.results);
        errorRef.current?.scrollIntoView({
          behavior: "smooth",
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const developerPortalLogin = () => {
    return (
      <div>
        <p>
          {"If you need to login, select the login type for your Marketplace."}
        </p>
      </div>
    );
  };

  const renderCreateIntegration = () => {
    return (
      <div>
        <div className="flex items-align">
          {'Once logged in, click on "Add New Key for A Solution Provider"'}
        </div>
        <div className="flex items-align mt-4">
          <img
            width={DEFAULT_IMAGE_WIDTH}
            src="https://developer.walmart.com/image/asdp/doc-images/delegate-access-screen.png"
            alt=""
          />
        </div>
        <div>{"Select Rutter from drop-down of solution providers."}</div>
        <div className="flex items-align mt-4">
          <img
            width={DEFAULT_IMAGE_WIDTH}
            src="https://developer.walmart.com/image/asdp/doc-images/select-sol-prov.png"
            alt=""
          />
        </div>
        <div>
          Then, in the permissions page, grant "ViewOnly" for all scopes
        </div>
        <div className="flex mt-4">
          <div>
            Click "Submit", and then enter the credentials in the fields below.
          </div>
        </div>
      </div>
    );
  };

  return (
    <div style={{ padding: "1.6rem 3rem 200px", minHeight: "100%" }}>
      <div style={{ display: "flex" }} className="">
        {!autoPlatform && (
          <Button
            onClick={props.onBack}
            icon={<ArrowLeftOutlined />}
            className="flex items-center"
          >
            Back
          </Button>
        )}
      </div>
      <div className="flex justify-center mb-24">
        <div className="w-4/6">
          <div
            className="flex  flex-column"
            style={{ justifyContent: "center", paddingBottom: "20px" }}
          >
            <img
              style={{ height: "80px" }}
              src="https://jobapplications.net/wp-content/uploads/2016/11/walmart-logo-square.png"
              alt=""
            />
          </div>

          <Steps
            current={windowOpened ? 1 : 0}
            direction="vertical"
            className="mt-4"
          >
            <Step
              title={
                <Link
                  onClick={() => {
                    // depending on size of available screen, we may want to resize link widnow to make it smaller
                    let width = window.screen.width - window.innerWidth;

                    if (window.innerWidth === window.screen.width) {
                      width = window.innerWidth / 2;
                    }
                    myPopup(
                      `https://developer.walmart.com/account/generateKey`,
                      "web",
                      width,
                      window.innerHeight,
                    );
                  }}
                >
                  Click here to Open the Walmart Developer Portal
                </Link>
              }
              description=""
            />

            <Step
              title={<div>Login to the Developer Portal</div>}
              status="process"
              description={developerPortalLogin()}
            />
            <Step
              title={<div>Configure a New Integration</div>}
              status="process"
              description={renderCreateIntegration()}
            />
            <Form
              layout="vertical"
              form={form}
              style={{ paddingBottom: 40 }}
              onFinish={handleFinish}
            >
              <Form.Item
                label="Client ID"
                name="client_id"
                rules={[{ required: true, message: "Client ID required" }]}
              >
                <Input placeholder="Walmart Client ID" />
              </Form.Item>
              <Form.Item
                label="Client Secret"
                name="client_secet"
                rules={[{ required: true, message: "Client Secret required" }]}
              >
                <Input.Password placeholder="***********************" />
              </Form.Item>
              <Form.Item
                label="Walmart Store Region"
                className="flex flex-row items-center"
              >
                <Select
                  showSearch
                  style={{ width: "100%", marginTop: 20, marginBottom: 20 }}
                  onSelect={(val: any) => setRegion(val as string)}
                  defaultValue={region ? region : WALMART_REGION.UNITED_STATES}
                >
                  {Object.keys(WALMART_REGION).map((region) => (
                    <Select.Option key={region} value={region}>
                      {(WALMART_REGION_HUMAN_READABLE as any)[region]}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item>
                {error && (
                  <Alert
                    className="mb-4"
                    type="error"
                    message={"Error"}
                    description={error}
                    showIcon
                  ></Alert>
                )}
                <Button type="primary" htmlType="submit" disabled={loading}>
                  {loading ? "Connecting..." : "Finish"}
                </Button>
              </Form.Item>
            </Form>
          </Steps>
          <div ref={errorRef}>
            <ConnectionErrors platform="Walmart" errors={validationResponse} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Walmart;
