import * as amplitude from "@amplitude/analytics-browser";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { Alert, Button, Select } from "antd";
import axios from "axios";
import qs from "query-string";
import { useState } from "react";
import { useHistory } from "react-router";
import { UNKNOWN_ERROR_MESSAGE } from "../constants";
import { persistLinkState } from "../hooks/useLinkTracker";
import { captureException } from "../sentry";
import Store from "../stateStore";
import { extractLinkParamsFromState, getBackendUrl, STORES } from "../utils";
import TextUnderConnect, {
  shouldShowTextUnderConnect,
} from "./shared/TextUnderConnect";

export enum AMAZON_REGION {
  UNITED_STATES = "UNITED_STATES",
  CANADA = "CANADA",
  MEXICO = "MEXICO",
  BRAZIL = "BRAZIL",
  UNITED_KINGDOM = "UNITED_KINGDOM",
  GERMANY = "GERMANY",
  FRANCE = "FRANCE",
  SPAIN = "SPAIN",
  ITALY = "ITALY",
  NETHERLANDS = "NETHERLANDS",
  SWEDEN = "SWEDEN",
  JAPAN = "JAPAN",
  UNITED_ARAB_EMIRATES = "UNITED_ARAB_EMIRATES",
  INDIA = "INDIA",
  TURKEY = "TURKEY",
  EGYPT = "EGYPT",
  SINGAPORE = "SINGAPORE",
  AUSTRALIA = "AUSTRALIA",
  SAUDI_ARABIA = "SAUDI_ARABIA",
  POLAND = "POLAND",
}

const AMAZON_REGION_HUMAN_READABLE = {
  UNITED_STATES: "United States",
  CANADA: "Canada",
  MEXICO: "Mexico",
  BRAZIL: "Brazil",
  UNITED_KINGDOM: "United Kingdom",
  GERMANY: "Germany",
  FRANCE: "France",
  SPAIN: "Spain",
  ITALY: "Italy",
  NETHERLANDS: "Netherlands",
  SWEDEN: "Sweden",
  JAPAN: "Japan",
  AUSTRALIA: "Australia",
  UNITED_ARAB_EMIRATES: "United Arab Emirates",
  INDIA: "India",
  TURKEY: "Turkey",
  EGYPT: "Egypt",
  SINGAPORE: "Singapore",
  SAUDI_ARABIA: "Saudi Arabia",
  POLAND: "Poland",
};

type Props = {
  clientSecret: string;
  onBack: () => any;
  onCancel: () => any;
  onComplete: any;
};

function Amazon(props: Props) {
  amplitude.setGroup("platform", "AMAZON");
  const { clientSecret, onComplete } = props;

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  const stateStore = Store.useStore();
  const isSandbox = stateStore.get("isSandbox");
  const autoPlatform = stateStore.get("autoPlatform");
  const organizationId = stateStore.get("orgId");
  const org = stateStore.get("organization");
  const nonce = stateStore.get("nonce");
  const origin = stateStore.get("origin");
  const savedRegion = stateStore.get("platformData").region;
  const autoLoad = stateStore.get("autoLoad");
  const [region, setRegion] = useState<string>(savedRegion ? savedRegion : "");

  const history = useHistory();

  const handleFakeComplete = async () => {
    setIsLoading(true);
    try {
      const response = await axios.post(
        `${getBackendUrl()}/link/token/fulfill`,
        {
          type: STORES.AMAZON,
          clientId: clientSecret,
          website: "shop_good",
        },
      );
      const { data } = response;
      const { public_token } = data;
      onComplete(public_token);
    } catch (e: any) {
      handleAuthError(e);
    }
    setIsLoading(false);
  };

  const openSandboxAuth = () => {
    stateStore.set("handleSandboxComplete")(handleFakeComplete);
    history.push("/sandboxauth");
  };

  const openAmazonAuth = () => {
    let result: any;
    setIsLoading(true);
    try {
      persistLinkState(STORES.AMAZON, stateStore.getState());

      const q = qs.stringify({
        linkorigin: origin,
        linknonce: nonce,
        organizationId: organizationId,
        region: region,
        ...extractLinkParamsFromState(stateStore),
      });

      const url = `${getBackendUrl()}/amazon/link?${q}`;
      window.location.href = url;
    } catch (e) {
      handleAuthError(e);
      setIsLoading(false);
      return;
    }
  };

  const handleAuthError = (e: any) => {
    captureException(e);
    if (e.response?.data?.error_message) {
      setError(e.response.data?.error_message);
    } else {
      setError(UNKNOWN_ERROR_MESSAGE);
    }
  };

  if (autoLoad && region) {
    if (isSandbox) {
      openSandboxAuth();
    } else {
      openAmazonAuth();
    }
  }

  return (
    <div style={{ padding: "1.6rem" }}>
      <div style={{ display: "flex" }} className="">
        {!autoPlatform && (
          <Button
            onClick={props.onBack}
            icon={<ArrowLeftOutlined />}
            className="flex items-center"
          >
            Back
          </Button>
        )}
      </div>
      <div
        className="flex items-center flex-column mt-4"
        style={{ justifyContent: "center", paddingBottom: "20px" }}
      >
        <img
          style={{ height: "80px" }}
          src="https://rutterpublicimages.s3.us-east-2.amazonaws.com/amazon-logo-square.jpg"
          alt=""
        />
      </div>
      <div className="font-semibold">Select your region</div>

      <Select
        showSearch
        style={{ width: "100%", marginTop: 20, marginBottom: 20 }}
        placeholder="Select region"
        onSelect={(val: any) => setRegion(val as string)}
        defaultValue={region ? region : undefined}
      >
        {Object.keys(AMAZON_REGION).map((region) => (
          <Select.Option key={region} value={region}>
            {(AMAZON_REGION_HUMAN_READABLE as any)[region]}
          </Select.Option>
        ))}
      </Select>
      {isSandbox ? (
        <Button
          type="primary"
          block
          disabled={isLoading || !region}
          onClick={openSandboxAuth}
        >
          Connect Sandbox
        </Button>
      ) : (
        <Button
          type="primary"
          block
          disabled={isLoading || !region}
          onClick={openAmazonAuth}
        >
          Connect
        </Button>
      )}
      {shouldShowTextUnderConnect(org) && (
        <div className="mt-2 text-xs">
          <TextUnderConnect></TextUnderConnect>
        </div>
      )}
      {error && (
        <Alert
          className="mt-4"
          type="error"
          message={"Error"}
          description={error}
          showIcon
        ></Alert>
      )}
    </div>
  );
}

export default Amazon;
